import React from 'react';
import {Routes, Route, Link } from 'react-router-dom';
// import 'antd/dist/reset.css'; 
import Home from './view/home';
import Product from './view/product';

const App = () => {
  const handleDateChange = (date, dateString) => {
    console.log('Selected Date:', dateString);
  };

  return (
    <div>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product/:productName" element={<Product />} />

        {/* <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} /> */}
      </Routes>
      {/* <Home></Home> */}
      {/* <h1>Welcome to React + Ant Design</h1>
      <Button type="primary">Primary Button</Button>
      <br />
      <DatePicker onChange={handleDateChange} style={{ marginTop: '20px' }} /> */}
    </div>
  );
};

export default App;

