import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import langs from '../locales/config.json';
import { Drawer, Statistic } from 'antd';
import WOW from 'wow.js';
import './style/home.css'
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';




const Home = () => {
    // React hook: useState 示例
    const [count, setCount] = useState(0);
    const [isShowLangs, setIsShowLangs] = useState(false);
    const [open, setOpen] = useState(false);
    const [screenWidth] = useState(window.innerWidth);
    const { t, i18n } = useTranslation();

    const { ref, inView } = useInView({
        threshold: 0.5, // 当元素 50% 可见时触发
        triggerOnce: true, // 仅触发一次
    });

    // 事件处理函数
    const handleClick = () => {
        setCount(count + 1);
    };

    const handleLangsClick = () => {
        setIsShowLangs(!isShowLangs)
    }
    const handleChangeLang = lang => {        
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang)
        setOpen(false)
    }
    const onClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        // 点击一次按钮就会触发一次useEffect
        console.log("执行了useEffect", i18n);
        new WOW().init();
        // return () => {
        //     console.log("组件卸载");
        // }
    }, []);

    const productList = [{
        name: 'SA7',
        describe: t('product_describe_1'),
        path: 'sa7'
    },
    {
        name: 'ST6',
        describe: t('product_describe_2'),
        path: 'st6'
    },
    {
        name: 'Web3',
        describe: t('product_describe_3'),
        path: 'web3'
    },
    {
        name: t('blockchain'),
        describe: t('product_describe_4'),
        path: 'blockchain'
    }]

    const productData = [{
        title: 'nation',
        value: 30,
        suffix: '+'

    },
    {
        title: 'customer',
        value: 300,
        suffix: '+'
    },
    {
        title: 'escrow_funds',
        value: 10,
        suffix: '+billion$'
    },
    {
        title: 'apas',
        value: 40,
        suffix: '+billion'
    }]

    const advantageList = [{
        name: 'risk',
        value: '0.0%'
    }, {
        name: 'lever',
        value: '1:500'
    }, {
        name: 'order',
        value: '< 20ms'
    }, {
        name: 'commission',
        value: '$0.00'
    },]

    const blockchain = [{
        bg: 'bg-[#f7f7f7]',
        titleColor: 'text-[black]',
        textColor: 'text-[#B9B9B9]'
    }, {
        bg: 'bg-[#6038bc]',
        titleColor: 'text-[white]',
        textColor: 'text-[white]'
    }, {
        bg: 'bg-[#40464f]',
        titleColor: 'text-[white]',
        textColor: 'text-[white]'
    }]

    const cryptoList = ['btc', 'eth', 'sol']

    const drawerStyles = {
        header: {
            display: 'none',
            //   color:'white'
        }
    }

    const formatter = (value) => <CountUp end={value} separator="," />;

    return (
        <div className='overflow-hidden'>
            <div className='relative hidden sm:block'>
                <img className='w-full' src={require("../assets/img/top_bg.png")} />
                <div className='wow animate__animated animate__backInUp font-bold top-title text-white absolute bottom-0 left-[10px] w-[200px] p-[10px] sm:left-[130px] sm:p-[70px] sm:w-[1000px]'>
                    <p className='text-[20px] leading-[20px] sm:text-[76px] sm:leading-[76px]'>{t('top_title_1')}</p>
                    <p className='text-[20px] leading-[20px] mt-[10px] sm:mt-[23px] sm:text-[76px] sm:leading-[76px]'>{t('top_title_2')}</p>
                    <p className='mt-[39px] text-[23px]'>{t('top_title_3')}</p>
                </div>
                {/* 语言切换 */}
                <div className='absolute right-[50px] top-[50px] hidden sm:block' onClick={handleLangsClick}>
                    <div className='flex text-white items-center cursor-pointer'>
                        <img className='w-[20px] h-[20px] mr-[10px]' src={require("../assets/img/lang_icon.png")} />
                        {langs[i18n.language]}
                        <img className='w-[20px] h-[20px] ml-[10px]' src={require(`../assets/img/${isShowLangs ? 'up' : 'donw'}_icon.png`)} />
                    </div>
                    <div className={`lang-dropdown overflow-hidden bg-white rounded-[10px] mt-[4px] ${isShowLangs ? 'h-[90px] py-[4px]' : 'h-[0px] py-0'}`}>
                        {Object.keys(langs).map(item => (
                            <p className='rounded-[10px] text-center text-[14px] my-[4px] cursor-pointer hover:text-[blue]' onClick={() => { handleChangeLang(item) }}>
                                {langs[item]}
                            </p>))}
                    </div>
                    {/* {isShowLangs ? <div className='bg-white rounded-[10px] mt-[4px] py-[4px] .lang-dropdown'>
                        {Object.keys(langs).map(item => (
                            <p className='rounded-[10px] text-center text-[14px] my-[4px] cursor-pointer hover:text-[blue]' onClick={() => { handleChangeLang(item) }}>
                                {langs[item]}
                            </p>))}
                    </div> : null} */}
                </div>
            </div>
            <div className='banner-m h-screen flex items-center sm:hidden'>
                <div className='flex justify-end absolute w-full h-[70px] top-[0px] bg-black bg-opacity-60'>
                    <img className='w-[24px] h-[24px] m-[20px]' src={require("../assets/img/lang_icon.png")} onClick={() => { setOpen(true) }} />
                </div>
                {/* style={{backgroundColor:'black',color:'white'}} */}

                <Drawer onClose={onClose} open={open} width="35%" styles={drawerStyles} style={{ backgroundColor: 'rgba(0,0,0,.7)', color: 'white' }}>
                    {Object.keys(langs).map(item => (
                        <p className='mt-[10px]' onClick={() => { handleChangeLang(item) }}>
                            {langs[item]}
                        </p>))}
                </Drawer>

                <div className='text-white text-center px-[20px]'>
                    <p className='text-[30px] font-bold'>{t('top_title_1')}</p>
                    <p className='text-[30px] font-bold'>{t('top_title_2')}</p>
                    <p className='mt-[40px]'>{t('top_title_3')}</p>
                </div>

            </div>

            <div className='px-[10px] sm:px-[130px]'>
                <div className='flex items-center py-[40px]'>
                    <div className='h-0 border-y flex-1'></div>
                    <div className='px-[40px] text-[30px]'>{t('hero_product')}</div>
                    <div className='h-0 border-y flex-1'></div>
                </div>
                <div className='flex flex-wrap sm:gap-x-[28px]'>
                    {productList.map((item, index) => (
                        <Link to={`/product/${item.path}`} target="_blank" className='w-full sm:flex-1 wow animate__animated animate__fadeIn'>
                            <div className='bg-gray cursor-pointer sm:hover:bg-black sm:hover:text-white mt-[10px]' key={index}>
                                <img src={require(`../assets/img/product_${index + 1}.png`)} className='bg-gray pt-[32px] pb-[15px] w-1/2 sm:w-full mx-auto' alt="" />
                                <div className='p-[22px]'>
                                    <p className={`text-[14px] sm:text-[20px] ${i18n.language=='en'?'sm:h-[160px]':'sm:h-[100px]'}`}>{item.describe}</p>
                                    <div className='flex justify-between items-end'>
                                        <p className='text-[20px] leading-[20px] mt-[28px] sm:text-[36px] sm:leading-[36px]'>{item.name}</p>
                                        <p className='text-[12px] sm:text-[16px]'>{`${t('detail')} >`}</p>
                                    </div>
                                </div>
                            </div>
                        </Link>

                    ))}
                </div>
            </div>
            <div className='bg-gray justify-around flex flex-wrap text-center px-[10px] mt-[30px] py-[20px] sm:py-[120px] sm:mt-[110px] sm:px-[130px]' ref={ref}>
                {inView ? productData.map(item => (<div className='w-1/2 sm:w-1/4 my-[20px]'>
                    <p className='text-[14px] sm:text-[16px]'>{t(item.title)}</p>
                    <Statistic valueStyle={{ fontSize: `${screenWidth > 500 ? '62' : '26'}px` }} value={item.value} formatter={formatter} suffix={item.suffix}></Statistic>
                </div>)) : null}
            </div>

            {/* 移动端 */}
            <div className='sm:hidden'>
                {/* section1 */}
                <div className='px-[20px]'>
                    <div className='flex justify-center mb-[20px] mt-[60px]'>
                        <div className='h-0 border-y w-[40px] mt-[16px]'></div>
                        <img src={require('../assets/img/num_1.png')} alt="" className='w-[30px] mx-[20px]' />
                        <div className='h-0 border-y w-[40px] mt-[16px]'></div>
                    </div>
                    <p className='text-[26px]'>{t('section_title_1')}</p>
                    <p className='text-[#C3B790] text-[16px] mt-[10px]'>{t('section_subtitle_1')}</p>
                    <div className='flex flex-wrap text-[14px]'>
                        {
                            [1, 2, 3, 4, 5, 6].map(item => (<div className='w-1/2 sm:w-1/3 flex items-center mt-[30px] sm:mt-[80px]'>
                                <p className='text-center rounded-full border-2 p-[2px] border-[#CD8D54] w-[26px] h-[26px] mr-[4px]'>{item}</p>
                                <p>{t(`advantage_${item}`)}</p>
                            </div>
                            ))
                        }
                    </div>
                    <img src={require('../assets/img/section_img_1.png')} alt="" className='w-full mt-[80px]' />
                    <div className='adv-wrap rounded-[20px] flex py-[10px]'>
                        {advantageList.map((item, index) => (
                            <div className='w-1/4 flex flex-col items-center justify-center text-white text-[12px]'>
                                <img src={require(`../assets/img/adv_icon_${index + 1}.png`)} alt="" className='w-[22px]' />
                                <p className='mt-[6px]'>{t(item.name)}</p>
                                <p className='font-bold'>{item.value}</p>
                            </div>
                        ))}
                    </div>
                </div>


                {/* section2 */}
                <div className='text-center mt-[100px]'>
                    <div className='flex justify-center mb-[20px]'>
                        <div className='h-0 border-y w-[40px] mt-[16px]'></div>
                        <img src={require('../assets/img/num_2.png')} alt="" className='w-[30px] mx-[20px]' />
                        <div className='h-0 border-y w-[40px] mt-[16px]'></div>
                    </div>
                    <img src={require(`../assets/img/section2_banner_${i18n.language == 'en' ? 'en' : 'zh'}.png`)} alt="" className='max-w-[160%] w-[160%] ml-[-30%]' />
                    <div className='px-[20px]'>
                        <p className='text-[38px] font-bold my-[10px]'>{t('flexible')}</p>
                        <p className='text-[24px] my-[10px]'>{t('section_title_2')}</p>
                        <p className='text-[#525252]'>{t('section_subtitle_2')}</p>
                    </div>


                </div>

                {/* section3 */}
                <div className='mt-[80px] text-center px-[20px]'>
                    <div className='flex justify-center mb-[20px]'>
                        <div className='h-0 border-y w-[40px] mt-[16px]'></div>
                        <img src={require('../assets/img/num_3.png')} alt="" className='w-[30px] mx-[20px]' />
                        <div className='h-0 border-y w-[40px] mt-[16px]'></div>
                    </div>
                    <p className='font-bold text-[38px] leading-[38px]'>Web 3.0</p>
                    <p className='text-[28px]'>{t('section_title_3')}</p>
                    <img src={require('../assets/img/section_img_3.png')} alt="" className='w-full mt-[20px]' />

                    <div className='bg-[#ECECEC] rounded-[20px] p-[30px] text-[#8F8F8F] mt-[20px]'>
                        <img src={require('../assets/img/icon_cloud_approved.png')} alt="" className='w-[57px] h-[48px] mx-auto' />
                        <p className='text-[20px] my-[10px]'>{t('approved')}</p>
                        <p className='text-[14px]'>{t('fx_introduce_content_1')}</p>
                    </div>

                    <div className='bg-[#ECECEC] rounded-[20px] p-[30px] text-[#8F8F8F] mt-[20px]'>
                        <img src={require('../assets/img/icon_cloud_safe.png')} alt="" className='w-[57px] h-[48px] mx-auto' />
                        <p className='text-[20px] my-[10px]'>{t('safe')}</p>
                        <p className='text-[14px]'>{t('fx_introduce_content_2')}</p>
                    </div>
                    <div className='text-start pl-[10px] pr-[20px]'>
                        {[1, 2, 3, 4, 5, 6].map(item => (<div className='flex mt-[40px]'>
                            <img src={require('../assets/img/fx_icon_2.png')} alt="" className='w-[26px] h-[26px]' />
                            <div className='pl-[4px]'>
                                <p className='mt-[2px] text-[16px] font-bold'>{t(`fx_advantage_title_${item}`)}</p>
                                <div className='w-[30px] border-b-[2px] my-[4px]'></div>
                                <div className='text-[12px] text-[#B9B9B9]'>{t(`fx_advantage_content_${item}`)}</div>
                            </div>
                        </div>))}
                    </div>
                </div>

                {/* section4 */}
                <div className='mt-[80px]'>
                    <div className='flex justify-center mb-[20px]'>
                        <div className='h-0 border-y w-[40px] mt-[16px]'></div>
                        <img src={require('../assets/img/num_4.png')} alt="" className='w-[30px] mx-[20px]' />
                        <div className='h-0 border-y w-[40px] mt-[16px]'></div>
                    </div>

                    <div className='text-center'>
                        <p className='font-bold text-[38px] leading-[38px]'>{t('blockchain')}</p>
                        <p className='text-[28px]'>{t('section_title_5')}</p>
                        <div className='mt-[40px]'>
                            {blockchain.map((item, index) => (<div className={`p-[60px] ${item.bg}`}>
                                <img src={require(`../assets/img/blockchain_icon_${index + 1}.png`)} alt="" className='w-[30px] h-[30px] mx-auto mb-[10px]' />
                                <p className={`${item.titleColor}`}>{t(`blockchain_title_${index + 1}`)}</p>
                                <div className='w-[30px] border-b-[1px] my-[12px] mx-[auto]'></div>
                                <p className={`${item.textColor} text-[12px]`}>{t(`blockchain_content_${index + 1}`)}</p>
                            </div>))}
                        </div>
                        <div className='flex items-center justify-center mt-[30px] mb-[10px]'>
                            <div className='h-0 border-y w-[30px]'></div>
                            <div className='px-[10px] text-[20px] text-[#6038bc]'>{t('rich')}</div>
                            <div className='h-0 border-y w-[30px]'></div>
                        </div>
                        <p className='text-[24px] text-[#464646] font-bold'>{t('blockchain_title_4')}</p>
                        <p className='text-[#636363] text-[16px] mt-[10px]'>{t('blockchain_title_5')}</p>
                        <div className='mt-[40px]'>
                            {
                                cryptoList.map((item, index) => (<div>
                                    <img src={require(`../assets/img/crypto_icon_${index + 1}.png`)} alt="" className='w-[103px] h-[103px] mx-auto' />
                                    <p className='my-[10px]'>{t(item)}</p>

                                    <div className='h-0 border-t w-[30px] mx-auto mb-[20px]'></div>
                                </div>))
                            }

                        </div>
                    </div>
                </div>
            </div>

            {/* pc端 */}
            <div className='hidden sm:block'>
                {/* section1 */}
                <div className='px-[130px]'>
                    <div className='mt-[144px] flex items-end'>
                        <img src={require('../assets/img/num_1.png')} alt="" className='w-[80px] h-[80px]' />
                        <p className='text-[60px]'>{t('section_title_1')}</p>
                    </div>
                    <p className='text-[#C3B790] pl-[110px] text-[24px] mt-[40px]'>{t('section_subtitle_1')}</p>
                    <div className='flex flex-wrap pl-[110px] text-[22px]'>
                        {
                            [1, 2, 3, 4, 5, 6].map(item => (<div className='w-1/3 flex items-center mt-[80px]'>
                                <p className='text-center rounded-full border-2 p-[2px] border-[#CD8D54] w-[40px] h-[40px] mr-[20px] flex-0'>{item}</p>
                                <p className='text-nowrap flex'>{t(`advantage_${item}`)}</p>
                            </div>
                            ))
                        }
                    </div>
                    <img src={require('../assets/img/section_img_1.png')} alt="" className='w-full mt-[80px]' />
                    <div className='adv-wrap rounded-[20px] flex py-[40px]'>
                        {advantageList.map((item, index) => (
                            <div className='w-1/4 flex flex-col items-center justify-center text-white text-[30px]'>
                                <img src={require(`../assets/img/adv_icon_${index + 1}.png`)} alt="" className='w-[50px]' />
                                <p className='mt-[16px] text-nowrap'>{t(item.name)}</p>
                                <p className='font-bold'>{item.value}</p>
                            </div>
                        ))}
                    </div>
                </div>


                {/* section2 */}
                <div className='mt-[144px]'>
                    <div className='relative'>
                        <img src={require('../assets/img/num_2_white.png')} alt="" className='w-[80px] h-[80px] absolute top-[10%] left-[7%]' />
                        <img src={require(`../assets/img/section2_banner_${i18n.language == 'en' ? 'en' : 'zh'}.png`)} alt="" className='w-full' />
                    </div>

                    <div className='px-[130px]'>
                        <div className='flex mt-[130px]'>
                            <p className='text-[88px] leading-[88px] font-bold mr-[62px] text-nowrap'>{t('flexible')}</p>
                            <div>
                                <p className='text-[36px] leading-[36px]'>{t('section_title_2')}</p>
                                <p className='text-[24px] text-[#525252] mt-[18px]'>{t('section_subtitle_2')}</p>
                            </div>
                        </div>
                        <img src={require('../assets/img/section_img_2.png')} alt="" className='w-full mt-[20px]' />
                    </div>


                </div>



                {/* section3 */}
                <div className='mt-[144px] px-[130px] justify-center items-start flex'>
                    <img src={require('../assets/img/num_3.png')} alt="" className='w-[90px] h-[80px]' />
                    <div className='flex-1 pr-[75px]'>
                        <div className='flex'>
                            <div className='w-1/2'>
                                <p className='font-bold text-[78px] leading-[78px]'>Web 3.0</p>
                                <p className='text-[58px]'>{t('section_title_3')}</p>
                                <div className='w-4/5 bg-[#ECECEC] rounded-[20px] p-[30px] text-[#8F8F8F] flex justify-between items-center mt-[90px] wow animate__animated animate__fadeInLeft'>
                                    <div className='w-4/5'>
                                        <p className='text-[26px] mb-[20px]'>{t('approved')}</p>
                                        <p>{t('fx_introduce_content_1')}</p>
                                    </div>
                                    <img src={require('../assets/img/icon_cloud_approved.png')} alt="" className='w-[97px] h-[88px]' />
                                </div>
                                <div className='w-4/5 bg-[#ECECEC] rounded-[20px] p-[30px] text-[#8F8F8F] flex justify-between items-center mt-[50px] wow animate__animated animate__fadeInLeft'>
                                    <div className='w-4/5'>
                                        <p className='text-[26px] mb-[20px]'>{t('safe')}</p>
                                        <p>{t('fx_introduce_content_2')}</p>
                                    </div>
                                    <img src={require('../assets/img/icon_cloud_safe.png')} alt="" className='w-[97px] h-[88px]' />
                                </div>
                            </div>
                            <div className='w-1/2'>
                                <img src={require('../assets/img/section_img_3.png')} alt="" className='w-full mt-[200px] wow animate__animated animate__fadeInRight' />
                            </div>
                        </div>
                        <div className='flex flex-wrap'>
                            {[1, 2, 3, 4, 5, 6].map(item => (<div className='flex w-1/3 mt-[40px]'>
                                <img src={require(`../assets/img/fx_icon_${item}.png`)} alt="" className='w-[60px] h-[60px]' />
                                <div className='pl-[10px] pr-[60px]'>
                                    <p className='mt-[4px] text-[20px]'>{t(`fx_advantage_title_${item}`)}</p>
                                    <div className='w-[30px] border-b-[3px] my-[10px]'></div>
                                    <div className='text-[16px] text-[#B9B9B9]'>{t(`fx_advantage_content_${item}`)}</div>
                                </div>
                            </div>))}
                        </div>
                    </div>
                </div>

                {/* section4 */}
                <div className='mt-[144px] px-[130px] flex justify-center'>
                    <img src={require('../assets/img/num_4.png')} alt="" className='w-[90px] h-[80px]' />
                    <div className='flex-1 pr-[120px] text-center'>
                        <p className='text-[78px] leading-[78px] font-bold text-start'>{t('blockchain')}</p>
                        <p className='text-[78px] text-start'>{t('section_title_5')}</p>

                        <div className='flex mt-[80px]'>
                            {blockchain.map((item, index) => (<div className={`w-1/3 p-[60px] ${item.bg}`}>
                                <img src={require(`../assets/img/blockchain_icon_${index + 1}.png`)} alt="" className='w-[60px] h-[60px] mx-auto mb-[40px]' />
                                <p className={`${item.titleColor}`}>{t(`blockchain_title_${index + 1}`)}</p>
                                <p className={`${item.textColor}`}>{t(`blockchain_content_${index + 1}`)}</p>
                            </div>))}
                        </div>
                        <div className='flex items-center justify-center py-[40px]'>
                            <div className='h-0 border-y w-[30px]'></div>
                            <div className='px-[20px] text-[30px] text-[#6038bc]'>{t('rich')}</div>
                            <div className='h-0 border-y w-[30px]'></div>
                        </div>
                        <p className='text-[36px] text-[#464646] font-bold'>{t('blockchain_title_4')}</p>
                        <p className='text-[#636363] text-[18px] mt-[20px]'>{t('blockchain_title_5')}</p>
                        <div className='flex justify-around mt-[40px]'>
                            {
                                cryptoList.map((item, index) => (<div className='wow animate__animated animate__fadeInUp'>
                                    <img src={require(`../assets/img/crypto_icon_${index + 1}.png`)} alt="" className='w-[183px] h-[183px]' />
                                    <p className='my-[30px]'>{t(item)}</p>
                                    <div className='h-0 border-y-2 w-[30px] mx-auto'></div>
                                </div>))
                            }

                        </div>
                    </div>
                </div>
            </div>
            {/* bottom-pc*/}
            <div className='bg-black text-white py-[40px] h-[400px] mt-[100px] justify-center hidden sm:flex'>
                <div className='border-r border-[#212121] w-1/5 p-[20px]'>
                    <p>{t('contact')}：</p>
                    <div className='border-t border-white w-[50px] mt-[10px]'></div>
                </div>
                <div className='border-r border-[#212121] w-1/5 p-[20px]'>
                    <p>{t('social_links')}：</p>
                    <div className='border-t border-white w-[50px] mt-[10px]'></div>
                </div>
                <div className='border-r border-[#212121] w-1/5 p-[20px]'>
                    <p>{t('links')}：</p>
                    <div className='border-t border-white w-[50px] mt-[10px]'></div>
                </div>
                <div className='w-1/5 p-[20px]'>
                    <p>{t('about')}：</p>
                    <div className='border-t border-white w-[50px] mt-[10px]'></div>
                </div>
            </div>

            {/* bottom-h5*/}
            <div className='bg-black text-white py-[20px] mt-[100px] text-[12px] sm:hidden'>
                <div className='p-[20px]'>
                    <p>{t('contact')}：</p>
                    <div className='border-t border-white w-[10px] mt-[2px]'></div>
                </div>
                <div className='p-[20px]'>
                    <p>{t('social_links')}：</p>
                    <div className='border-t border-white w-[10px] mt-[2px]'></div>
                </div>
                <div className='p-[20px]'>
                    <p>{t('links')}：</p>
                    <div className='border-t border-white w-[10px] mt-[2px]'></div>
                </div>
                <div className='p-[20px]'>
                    <p>{t('about')}：</p>
                    <div className='border-t border-white w-[10px] mt-[2px]'></div>
                </div>
            </div>
        </div>
    );
}

export default Home;