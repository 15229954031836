import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"
import { Link } from 'react-router-dom';
import useStore from '../store/index.js'
import { useTranslation } from "react-i18next"
import './style/home.css'

const Product = () => {
    const { productName } = useParams()
   
    const { t, i18n } = useTranslation();
    const { title, url, full, account, password } = useStore()[productName];
    return (
        <div>
            <div className='banner-product h-[400px] text-[white] flex items-center justify-center text-center'>
                <div>
                    <p className='text-[60px]'>{title}</p>
                    <p className='text-[30px]'>{full}</p>
                </div>
            </div>
            <div className='flex items-center py-[40px]'>
                <div className='h-0 border-y flex-1'></div>
                <div className='px-[10px] text-[20px] sm:text-[30px] sm:px-[30px]'>{t('demo_version')}</div>
                <div className='h-0 border-y flex-1'></div>
            </div>
            <div className='w-2/3 mx-auto text-[14px] sm:text-[18px]'>
                <p>{t('address')}:</p>
                <Link to={url} target="_blank" className='text-[blue] text-[18px] sm:text-[24px]'>{url}</Link>

                <p className='mt-[20px]'>{t('account')}:</p>
                <p className='text-[18px] sm:text-[24px]'>{account}</p>

                <p className='mt-[20px]'>{t('password')}:</p>
                <p className='text-[18px] sm:text-[24px]'>{password}</p>
            </div>

        </div>
    );
}

export default Product;