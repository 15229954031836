import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// 导入翻译文件
import en from './locales/en.json';
import zh from './locales/zh.json';
import zh_hk from './locales/zh_hk.json';

// 配置 i18n
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    zh: { translation: zh },
    zh_hk: { translation: zh_hk },
  },
  lng: localStorage.getItem('lang')||'en', // 默认语言
  fallbackLng: localStorage.getItem('lang')||'en', // 回退语言
  interpolation: {
    escapeValue: false, // React 会自动防止 XSS
  },
});

export default i18n;