import { create } from 'zustand';


const useStore = create((set) => ({
    //   count: 0,
    //   increment: () => set((state) => ({ count: state.count + 1 })), // 更新状态
    //   decrement: () => set((state) => ({ count: state.count - 1 })),
    //   reset: () => set({ count: 0 }),
    sa7: {
        title: 'SA7',
        full: 'SuperAI Options 7',
        url: 'http://superapp.superai7.cc/#/',
        account: '123@123.com',
        password: '123456'

    },
    st6: {
        title: 'ST6',
        full: 'SmartAI Trading 6',
        url: 'http://h5.smartaitrading6.cc/#/',
        account: '123@123.com',
        password: '123456'

    },
    web3: {
        title: 'Web3',
        full: 'Web3',
        url: 'https://h5.rx7-main.vip/#/',
        account: '-',
        password: '-'

    },
    blockchain: {
        title: 'Blockchain',
        full: 'Blockchain',
        url: 'https://block.fxspeed.vip/firefly/#/',
        account: 'meta@gmail.com',
        password: '123456'

    }
}))

export default useStore
